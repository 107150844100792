// action
$alert: #ffa721;
$negative: #ff3d3d;
$positive: #1d9a41;

// brand
$accent: #8bf7f2;
$action: #6c6dc5;
$blend-bg: linear-gradient(206.87deg, #6c6dc4 -18.97%, #8bf7f2 77.07%);
$opaque: rgba(108, 109, 197, 0.2);

// core
$light-80: #eeeeee;
$light-100: #ffffff;
$dark-70: #333333;
$light-55: #d9d9d9;
$dark-100: #1f1f1f;
$dark-45: #5e5e5e;
$neutral: #aaaaaa;

// game
$defender: #409de9;
$attacker: #fb6c56;

:export {
	alert: $alert;
	negative: $negative;
	positive: $positive;

	accent: $accent;
	action: $action;
	blend-bg: $blend-bg;
	opaque: $opaque;

	light-80: $light-80;
	light-100: $light-100;
	dark-70: $dark-70;
	light-55: $light-55;
	dark-100: $dark-100;
	dark-45: $dark-45;
	neutral: $neutral;

	defender: $defender;
	attacker: $attacker;
}

@mixin display-large {
	font-family: 'Voyage-Bold';
	font-size: 64px;
	line-height: 80px;
	font-weight: 500;
	letter-spacing: 0.015em;
}

@mixin display-medium {
	font-family: 'Voyage-Bold';
	font-size: 40px;
	line-height: 134%;
	font-weight: 500;
	letter-spacing: 0.015em;
}

@mixin subtitle-small {
	font-family: 'Inter-Medium';
	font-size: 20px;
	line-height: 134%;
	font-weight: 500;
	letter-spacing: 0.015em;
}

@mixin subtitle-xs {
	font-family: 'Inter-Medium';
	font-size: 20px;
	line-height: 150%;
	font-weight: 500;
	letter-spacing: 0.03em;
}

@mixin superhead {
	font-family: 'Inter-Medium';
	font-size: 18px;
	line-height: 24px;
	font-weight: 500;
	letter-spacing: 0.1em;
	text-transform: uppercase;
}

@mixin body-small {
	font-family: 'Inter-Medium';
	font-size: 16px;
	line-height: 24px;
	font-weight: 500;
	letter-spacing: 0.03em;
}

@mixin body-xs {
	font-family: 'Inter-Medium';
	font-size: 14px;
	line-height: 150%;
	font-weight: 500;
	letter-spacing: 0.03em;
}

@mixin caption {
	font-family: 'Inter-Medium';
	font-size: 12px;
	line-height: 16px;
	font-weight: 500;
	letter-spacing: 0.03em;
}
