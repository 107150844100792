@import 'styles/icons', 'styles/theme', 'styles/grid.scss';

@font-face {
	font-family: 'Inter-Medium';
	src: local('Inter-Medium'), url(./assets/fonts/Inter-Medium.otf) format('opentype');
}

@font-face {
	font-family: 'Voyage-Bold';
	src: local('Voyage-Bold'), url(./assets/fonts/Voyage-Bold.otf) format('opentype');
}

body {
	margin: 0;
	font-family: 'Inter-Medium', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
		'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
	box-sizing: border-box;
}
