@font-face {
	font-family: 'fontello';
	src: url('../assets/fonts/fontello.eot?6135306');
	src: url('../assets/fonts/fontello.eot?6135306#iefix') format('embedded-opentype'),
		url('../assets/fonts/fontello.woff2?6135306') format('woff2'),
		url('../assets/fonts/fontello.woff?6135306') format('woff'),
		url('../assets/fonts/fontello.ttf?6135306') format('truetype'),
		url('../assets/fonts/fontello.svg?6135306#fontello') format('svg');
	font-weight: normal;
	font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
  @media screen and (-webkit-min-device-pixel-ratio:0) {
    @font-face {
      font-family: 'fontello';
      src: url('../font/fontello.svg?6135306#fontello') format('svg');
    }
  }
*/

[class^='icon-']:before,
[class*=' icon-']:before {
	font-family: 'fontello';
	font-style: normal;
	font-weight: normal;
	speak: none;
	display: inline-block;
	text-decoration: inherit;
	width: 24px;
	font-size: 24px;
	height: 24px;
	margin-top: 2px;
	text-align: center;
	font-variant: normal;
	text-transform: none;
	line-height: 24px;
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.icon-menu:before {
	content: '\e800';
} /* '' */
.icon-more:before {
	content: '\e801';
} /* '' */
.icon-notification:before {
	content: '\e802';
} /* '' */
.icon-plus:before {
	content: '\e803';
} /* '' */
.icon-remove_circle:before {
	content: '\e804';
} /* '' */
.icon-save:before {
	content: '\e805';
} /* '' */
.icon-search:before {
	content: '\e806';
} /* '' */
.icon-settings:before {
	content: '\e807';
} /* '' */
.icon-share:before {
	content: '\e808';
} /* '' */
.icon-shield:before {
	content: '\e809';
} /* '' */
.icon-sword:before {
	content: '\e80a';
} /* '' */
.icon-trashcan:before {
	content: '\e80b';
} /* '' */
.icon-warning:before {
	content: '\e80c';
} /* '' */
.icon-add_circle:before {
	content: '\e80d';
} /* '' */
.icon-arrow_back:before {
	content: '\e80e';
} /* '' */
.icon-arrow_downward:before {
	content: '\e80f';
} /* '' */
.icon-arrow_forward:before {
	content: '\e810';
} /* '' */
.icon-arrow_upward:before {
	content: '\e811';
} /* '' */
.icon-bookmark:before {
	content: '\e812';
} /* '' */
.icon-bookmark_filled:before {
	content: '\e813';
} /* '' */
.icon-check:before {
	content: '\e814';
} /* '' */
.icon-check_circle:before {
	content: '\e815';
} /* '' */
.icon-close:before {
	content: '\e816';
} /* '' */
.icon-edit:before {
	content: '\e817';
} /* '' */
.icon-error:before {
	content: '\e818';
} /* '' */
.icon-exit:before {
	content: '\e819';
} /* '' */
.icon-expand_less:before {
	content: '\e81a';
} /* '' */
.icon-expand_more:before {
	content: '\e81b';
} /* '' */
.icon-filter:before {
	content: '\e81c';
} /* '' */
.icon-group:before {
	content: '\e81d';
} /* '' */
.icon-link:before {
	content: '\e81e';
} /* '' */
