@import 'theme';

.app {
	background: $blend-bg;
	height: 100vh;
	width: auto;
	display: flex;
	display: -webkit-flex;
	justify-content: center;
	-webkit-justify-content: center;
	align-items: center;
	-webkit-align-items: center;

	.container {
		margin-top: -80px;
		width: 448px;
		color: white;
		@media only screen and (max-width: 550px) {
			margin-top: -160px;
			width: 327px;
		}
	}

	.body-text {
		@include body-small;
		font-family: 'Inter-Medium';
		margin-bottom: 24px;
	}

	.header {
		@include display-medium;
		font-family: 'Voyage-Bold';
		margin-bottom: 24px;
	}

	.logos {
		display: flex;
	}
}
